import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "../../../layouts/default";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BuyerSnippet from "../../../components/Defaulter/BuyerSnippet";
import SupplierSnippet from "../../../components/Defaulter/SupplierSnippet";
import { getPermission } from "../../../store/slices/defaulter";
import { getPayment } from "../../../store/slices/creditqDefaulters/payments";
import { getDefaulter } from "../../../store/slices/creditqDefaulters/index.js";
import { clearMessage } from "../../../store/slices/message";
import { useNavigate } from "react-router-dom";
import {
  capitalizeAllLetters,
  sanitizeInput,
  getToken,
} from "../../../helpers/common";
import * as apiService from "../../../services/api";
import * as API_URL from "../../../constants/api";

const CQPaymentCreate = () => {
  const { type, defaulter_id } = useParams();
  const [defaulterData, setDefaulterData] = useState("");
  const [paymentAmountToHold, setPaymentAmountToHold] = useState(0);
  const { creditqDefaulter } = useSelector(
    (state) => state.creditqDefaultersSlice
  );
  const { paymentDetails } = useSelector((state) => state.paymentTypeSlice);
  const dispatch = useDispatch();
  const [paymentProofFileBase64, setPaymentProofFileBase64] = useState("");
  const [paymentProofFileName, setPaymentProofFileName] = useState("");
  const [dueAmount, setDueAmount] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    defaulterId: defaulter_id,
    settlementType: type,
    initialApproverEmailId: "",
    transaction_date: "",
    receivedAmount: "",
    remark: "",
    evidence: "",
    paymentProof: "",
    paymentProofName: "",
    paymentProofFile: "",
    ...(type === "full" ? { writeOff: dueAmount } : { dueAmount: dueAmount }),
  });

  useEffect(() => {
    dispatch(clearMessage());
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = { id: Number(id) };
        dispatch(getDefaulter({ filterData }));
      }
    };
    fetchDefaulter(defaulter_id);
  }, [defaulter_id, dispatch]);
  useEffect(() => {
    if (creditqDefaulter && creditqDefaulter.data)
      setDefaulterData(creditqDefaulter?.data.defaulter);
  }, [creditqDefaulter]);

  useEffect(() => {
    dispatch(getPayment(defaulter_id));
  }, [dispatch, defaulter_id]);
  useEffect(() => {
    if (defaulterData?.payment_due_amount) {
      const calculatedDueAmount =
        defaulterData.payment_due_amount -
        defaulterData.settled_amount -
        receivedAmount;
      setDueAmount(calculatedDueAmount > 0 ? calculatedDueAmount : 0);
    }
  }, [receivedAmount, defaulterData]);

  const amountToBeOnHoldForSafety = useMemo(() => {
    let totalAmountToBeHold = 0;
    if (Array.isArray(paymentDetails?.data)) {
      const pendingEntries = paymentDetails.data.filter(
        (data) =>
          data.settlement_type === "partial" &&
          data.final_approval_status === "pending"
      );
      totalAmountToBeHold = pendingEntries.reduce((sum, entry) => {
        return sum + Number(entry.amount) || 0;
      }, 0);
    }
    return totalAmountToBeHold;
  }, [paymentDetails]);

  useEffect(() => {
    if (amountToBeOnHoldForSafety > 0)
      setPaymentAmountToHold(amountToBeOnHoldForSafety);
  }, [amountToBeOnHoldForSafety]);

  const validationSchema = Yup.object().shape({
    remark: Yup.string().trim().required().label("Remark"),
    initialApproverEmailId: Yup.string()
      .trim()
      .required()
      .email()
      .label("Email ID"),
    receivedAmount:
      type !== "remove"
        ? Yup.number()
            .typeError("Amount must be a valid number")
            .required("Amount is required")
            .min(1, "Amount must be at least 1")
            .max(
              defaulterData?.payment_due_amount,
              `Amount cannot be greater than ${
                defaulterData?.payment_due_amount - paymentAmountToHold
              }`
            )
            .label("Amount")
        : Yup.number().notRequired(),
    paymentProof: Yup.mixed().required().label("Evidence"),
    transaction_date:
      type !== "remove" && Yup.date().required("Transaction date is required"),
  });
  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPaymentProofFileBase64(reader.result);
        setPaymentProofFileName(file.name);
        setFieldValue("paymentProof", file);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("File is too large to get processed");
    }
  };

  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    setIsLoading(true);
    const sanitizedValues = {
      defaulterId: defaulter_id,
      settlementType: type !== "remove" ? type : "removed",
      initialApproverEmailId: sanitizeInput(values.initialApproverEmailId),
      receivedAmount: sanitizeInput(
        type !== "remove" ? values.receivedAmount : 0
      ),
      remark: sanitizeInput(values.remark),
      paymentProofName: sanitizeInput(values.paymentProofName),
      transaction_date: values.transaction_date,
      paymentProofFile: values.paymentProofFile,
      ...(type === "full" || type === "remove"
        ? { writeOff: dueAmount }
        : { dueAmount: dueAmount }),
    };
    let token = getToken();

    const result = await apiService.post(
      API_URL.UPDATE_PAYMENT_FOR_CREDITQ_DEFAULTER,
      sanitizedValues,
      token
    );
    if (result.status === 200) {
      setIsLoading(false);
      toast.success(result.message);
      navigate(`/creditq-defaulters/${defaulter_id}`);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Layout heading={`Settlement Page - ${capitalizeAllLetters(type)}`}>
        <div>
          <Row className="mb-5">
            <Col>
              <BuyerSnippet defaulter={defaulterData} />
            </Col>
            <Col>
              <SupplierSnippet defaulter={defaulterData} />
            </Col>
          </Row>
          {
            <Formik
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                if (values.paymentProof !== "") {
                  values.paymentProofName = paymentProofFileName;
                  values.paymentProofFile = paymentProofFileBase64;
                } else {
                  values.paymentProof = "";
                }
                handleSubmit(values);
              }}
              validationSchema={validationSchema}
              initialValues={values}
            >
              {({ isSubmitting, errors, touched, setFieldValue }) => (
                <>
                  <Form>
                    <div className="form-editable-section">
                      <Row>
                        <Col>
                          {defaulterData && (
                            <div className="form-box-mobile icon-box">
                              <label>
                                {capitalizeAllLetters("Total Amount")}
                              </label>
                              <p>
                                {defaulterData?.payment_due_amount
                                  ? defaulterData.payment_due_amount
                                  : ""}
                              </p>
                            </div>
                          )}
                        </Col>
                        <Col>
                          {type === "full" || type === "remove" ? (
                            <div className="form-box-mobile icon-box">
                              <label>{capitalizeAllLetters("Write off")}</label>
                              <p>{dueAmount}</p>
                            </div>
                          ) : type === "partial" ? (
                            <div className="form-box-mobile icon-box">
                              <label>
                                {capitalizeAllLetters("balance Amount")}
                              </label>
                              <p>{dueAmount}</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className="form-box-mobile icon-box">
                            <label>
                              {capitalizeAllLetters("Payment Due Date")}
                            </label>
                            {defaulterData?.payment_due_date ? (
                              <p>
                                {format(
                                  new Date(defaulterData?.payment_due_date),
                                  "d MMMM, yyyy"
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="form-box-mobile icon-box userInput">
                            <label>
                              {capitalizeAllLetters("Approving Authority")}
                            </label>
                            <Field
                              name="initialApproverEmailId"
                              onChange={(e) => {
                                setFieldValue(
                                  "initialApproverEmailId",
                                  e.target.value
                                );
                              }}
                              placeholder="Email ID"
                            />
                            {errors.initialApproverEmailId &&
                              touched.initialApproverEmailId && (
                                <div className="error">
                                  {errors.initialApproverEmailId}
                                </div>
                              )}
                          </div>
                        </Col>
                      </Row>
                      {type !== "remove" && (
                        <Row>
                          <Col>
                            <div className="form-box-mobile icon-box userInput">
                              <label>
                                {capitalizeAllLetters("settlement Amount")}
                              </label>
                              <Field
                                name="receivedAmount"
                                onChange={(e) => {
                                  setFieldValue(
                                    "receivedAmount",
                                    e.target.value
                                  );
                                  setReceivedAmount(e.target.value);
                                }}
                                placeholder="In Rupees"
                              />
                              {errors.receivedAmount &&
                                touched.receivedAmount && (
                                  <div className="error">
                                    {errors.receivedAmount}
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col>
                            <div className="form-box-mobile icon-box userInput">
                              <label htmlFor="transaction_date">
                                {capitalizeAllLetters("transaction date")}
                              </label>
                              <Field
                                name="transaction_date"
                                type="date"
                                className="form-control"
                                placeholder="Transaction date"
                                size="sm"
                                // value={(transaction_date)}
                                onChange={(e) => {
                                  setFieldValue(
                                    "transaction_date",
                                    e.target.value
                                  );
                                }}
                                max={format(new Date(), "yyyy-MM-dd")}
                                min={defaulterData && format(
                                  new Date(defaulterData?.verified_date),
                                  "yyyy-MM-dd"
                                )}
                              />{" "}
                              {errors.transaction_date &&
                                touched.transaction_date && (
                                  <div className="error">
                                    {errors.transaction_date}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <div className="form-box-mobile icon-box userInput">
                            <label>{capitalizeAllLetters("Remark")}</label>
                            <Field
                              name="remark"
                              onChange={(e) => {
                                setFieldValue("remark", e.target.value);
                              }}
                              component="textarea"
                            />
                            {errors.remark && touched.remark && (
                              <div className="error">{errors.remark}</div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="form-box-mobile icon-box userInput">
                          <label>{capitalizeAllLetters("Evidence")}</label>
                          <input
                            type="file"
                            id="fileUpload"
                            className="file-input"
                            onChange={(event) =>
                              handleFileChange(event, setFieldValue)
                            }
                            hidden
                          />
                          <label
                            style={{
                              border: "1px solid #007bff",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            htmlFor="fileUpload"
                            className="btn btn-upload"
                          >
                            {paymentProofFileName || "Choose File"}
                          </label>
                          {errors.paymentProof && touched.paymentProof && (
                            <div className="error">{errors.paymentProof}</div>
                          )}
                        </div>
                      </Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-theme btn-small mlauto mt20"
                          >
                            Settle
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          }
        </div>
      </Layout>
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

export default CQPaymentCreate;
